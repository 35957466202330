
.tab-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.2);
}

iframe {
  width: 99%;
  height: 99vh;
}


.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 60px;
  color: grey;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.tab.selected {
  color: blue;
}

.tab-icon {
  font-size: 24px;
}

.tab-label {
  font-size: 12px;
  margin-top: 5px;
}

.eventCard {
  width: calc(100vw / 1.5);
  height: calc((100vw / 1.5) * 1.299);
  border-radius: 13px;
  overflow: hidden;
  position: relative;
}

.eventCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.eventRowHeader {
  padding-left: 20px;
}


.eventCardDisplayBox {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.EventCardDisplayMessage {
  font-size: 14px;
  font-weight: 600;
}


.your-component-body {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.your-component-events-container {
  display: inline-flex;
  width: 100%;
}

.your-component-event {
  width: 300px;
  -webkit-tap-highlight-color: transparent;
}

.your-component-event-gap {
  flex-shrink: 0;
  width: 20px;
}

.venueCard {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: left;
  align-items: center;
  color: white;
  box-sizing: border-box;
  border-radius: 12px;
  width: 370px;
  height: 120px;
  overflow: hidden;
  margin-right: 10px;
  margin-top: 0%;
  margin-bottom: 0%;
}

.venueCard img {
  top: 50%;
  left: 50%;
  width: 370px;
}

.venueCardText {
  position: absolute;
  top: 10px;
  left: 18px;
}

.venue-showcase {
  display: flexbox;
  justify-content: center;
}

.venue-showcase ul {
  position: relative;
  list-style: none;
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.venue-showcase li {
  position: relative;
  list-style: none;
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.venueRowOne {
  margin-bottom: 0%;
}

.venueName {
  font-size: 24px;
  line-height: 1.2105263158;
  font-weight: 600;
  letter-spacing: .012em;
  color: white;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -12px;
}

.venueLocatedIn {
  font-size: 14px;
  line-height: 1.2105263158;
  letter-spacing: .012em;
  color: white;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -12px;
}